<template>
  <v-dialog v-model="open" max-width="400px">
    <v-card class="apply-mar-dialog">
      <v-card-title>{{
        $localizationService.localize("drp_import.apply_dialog.title")
      }}</v-card-title>

      <v-card-text class="pb-0">
        <p>
          {{ $localizationService.localize("drp_import.apply_dialog.text") }}
        </p>
        <v-checkbox
          v-model="shouldReplaceDriver"
          class="should-replace-driver"
          :label="
            $localizationService.localize('drp_import.apply_dialog.label')
          "
        ></v-checkbox>
      </v-card-text>

      <v-card-actions>
        <v-btn text @click="open = false">
          {{ $localizationService.localize("btn.cancel") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="applyImportSession()">
          {{
            $localizationService.localize("drp_import.apply_dialog.apply_btn")
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ApplyAmazonLogisticsDialog",

  data: () => ({
    open: false,
    shouldReplaceDriver: false,
  }),

  methods: {
    openDialog() {
      this.open = true;
    },

    clearData() {
      this.shouldReplaceDriver = false;
    },

    applyImportSession() {
      this.open = false;
      this.$emit("onApplyImportSession", this.shouldReplaceDriver);
    },
  },
};
</script>

<style lang="scss" >
.apply-mar-dialog {
  .should-replace-driver {
    label {
      font-size: 0.875rem !important;
    }
  }
}
</style>
